import styled from 'styled-components';

export const StyledActionButton = styled.button`
outline: 0;
padding: 0.5rem 0.7rem;
color: #ffffff;
background-color: #4a3e3e20;
cursor: pointer;
font-weight: bold;
transition: all 0.13678s ease-in-out;
border-radius: 40px;
border: 0;
margin-top: 20px;
&:hover {
    background-color: #ffffff40;
}
`;