import React, {createContext, useState, useContext } from "react";
import { UserContext } from "./UserContext";


const GameContext = createContext({
    players: [],
    playerTurn: 0,
    result: null,
    setResult: r => {},
});

const demoPlayer = [
    {
        username: "COMPUTER"
    }
]


const GameContextProvider = function ({children}) {
    const { user } = useContext(UserContext);
    const [result, setResult] = useState(null);
    const [players, setPlayers] = useState([user, ...demoPlayer]);
    const [playerTurn, setPlayerTurn] = useState(0);

    return <GameContext.Provider value={
        {
        players,
        setPlayers,
        playerTurn,
        setPlayerTurn,
        result,
        setResult,
        }
    }>
        {children}
    </GameContext.Provider>
}


export {
    GameContext,
    GameContextProvider
}