import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import history from "./utils/history";
import { GameContextProvider } from "./context/GameContext";
import { BackgroundContextProvider } from "./context/BackgroundContext";
import { UserContextProvider } from "./context/UserContext";
import {  StoneRockScissorContextProvider } from "./context/StoneRockScisssorContext";
import {GetTwoSimularCardGameContextProvider} from "./context/GetTwoSimiularCardGameContext";


ReactDOM.render(
  <Router history={history}>
    <UserContextProvider>
      <GameContextProvider>
      <StoneRockScissorContextProvider>
        <GetTwoSimularCardGameContextProvider>
        <BackgroundContextProvider>
          <App />
        </BackgroundContextProvider>
        </GetTwoSimularCardGameContextProvider>
        </StoneRockScissorContextProvider>
      </GameContextProvider>
    </UserContextProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
