import React, { useContext } from "react";
import Particles from "react-particles-js";
import styled from "styled-components";
import { BackgroundContext } from "../context/BackgroundContext";

const FullSize = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

export default function ParticlesSimple() {
  const { particles } = useContext(BackgroundContext);
  
    return (
    <FullSize>
      <Particles
        style={{
          width: "100%",
          height: "100%",
          position: "fixed"
        }}
        params={particles}
      />
    </FullSize>
  );
}
