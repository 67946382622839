import React, { useState, useContext } from 'react';
import Card from './GameCard';
import Styled from 'styled-components';
import {StyledText} from '../styleguides/StyledText';
import CardWrapper from '../styleguides/StyledGameCardsWrapper';
import { UserContext } from '../context/UserContext';
import Aux from '../utils/Auxillian';


const StyledInput = Styled.input`
border: none
padding: 0.2rem 1rem 
margin: 1rem
font: small-caption	
font-size: 0.8rem
width: calc(100% - 50px)
outline: none
line-height: 1.5
border-radius: 15px
` ;

export default function Register(props){
   const { user, registerUser } = useContext(UserContext);
   const [email, setEmail] = useState("");
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [isMatchingPassword, setIsMatchingPassword] = useState(true);
   const [verificationPassword, setVerificationPassword] = useState("");
   const [errorText, setErrorText] = useState(null);

    if(user) {
        props.history.push("/");
    }

   const isPasswordsMatchingCheck = (inputVertificationPassword) => {
       setVerificationPassword(inputVertificationPassword);
       setIsMatchingPassword(password === inputVertificationPassword);
   }


    return(
<Aux>
<CardWrapper>
    <Card>
        <form onSubmit={e => {
            e.preventDefault();

            if(!isMatchingPassword) {
                return;
            }
            
            setErrorText("");

            try {
                registerUser({
                    email,
                    username,
                    password
                });
                
                props.history.push("/trydicy")
           
            } catch(err) {
                setErrorText(err.message);
            }
            }}>
        <StyledText>Email</StyledText>
        <StyledInput onChange={e => setEmail(e.target.value)} type='email' required/>
        <StyledText>Username </StyledText>
        <StyledInput onChange={e => setUsername(e.target.value)} type='username' required/>
        <StyledText>Password  </StyledText>
        <StyledInput onChange={e => setPassword(e.target.value)} type='password' required/>
        <StyledText>Repeat password </StyledText>
        <StyledInput noAutoFill onChange={e => isPasswordsMatchingCheck(e.target.value)} type='password' required/>
        {
            !isMatchingPassword && (
                <span>Passwords must match!</span>
            )
        }
        <StyledInput  type="submit" value="Register" ></StyledInput>
        </form>
        {errorText && <StyledText>{errorText}</StyledText>}
    </Card>
</CardWrapper>
</Aux>
    )
}
