import React, { useContext, createContext, useState, useEffect } from "react";
import { GameContext } from './GameContext';

const choices = [
    {
        type: "✋",
        beats: "✊"
    },
    {
        type: "✌️",
        beats: "✋"
    },
    {
        type: "✊",
        beats: "✌️"
    }
]

const StoneRockScissorContext = createContext({
    selectType: type => { },
    resetGame: () => { },
    isGameOver: false,
    choices,
    firstPlayerSelection: null,
    secondPlayerSelection: null,
    selectRandomChoice: () => { },
    firstPlayerWinResultCounter: 0,
    secondPlayerWinResultCounter: 0,
});



const StoneRockScissorContextProvider = function ({ children }) {
    const { players, setPlayers, playerTurn, setPlayerTurn, setResult, result } = useContext(GameContext);
    const [firstPlayerSelection, setFirstPlayerSelection] = useState(null);
    const [secondPlayerSelection, setSecondPlayerSelection] = useState(null);
    const [firstPlayerWinResultCounter, setFirstPlayerResultCounter] = useState(0);
    const [secondPlayerWinResultCounter, setSecondPlayerResultCounter] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);


    function resetGame() {
        setResult(null);
        setFirstPlayerSelection(null);
        setSecondPlayerSelection(null);
        setIsGameOver(false);
    }


    function selectType(type) {
        if (playerTurn === 0) {
            setFirstPlayerSelection(type);
            setPlayerTurn(1);
            setPlayers(players);
        }
    }

    function selectRandomChoice() {
        const randomChoice = choices[Math.floor(Math.random() * choices.length)];
        setSecondPlayerSelection(randomChoice);
        validateResults(randomChoice);
    }


    function validateResults(type) {
        if (firstPlayerSelection.type === type.beats) {
            setResult("You lost, better luck next time!");
            setSecondPlayerResultCounter(secondPlayerWinResultCounter + 1);
            setIsGameOver(true);
        } else if (firstPlayerSelection.type === type.type) {
            setResult("It's a draw, play again?");
            setIsGameOver(true);
        } else {
            setResult("Congratulation you won");
            setFirstPlayerResultCounter(firstPlayerWinResultCounter + 1);
            setIsGameOver(true);
        }
        setPlayerTurn(0)
    }


    return <StoneRockScissorContext.Provider
        value={{
            players,
            selectType,
            resetGame,
            firstPlayerSelection,
            secondPlayerSelection,
            choices,
            selectRandomChoice,
            firstPlayerWinResultCounter,
            secondPlayerWinResultCounter,
            isGameOver,
            result,
            validateResults,
            firstPlayerWinResultCounter,
            playerTurn,
        }}>{children}</StoneRockScissorContext.Provider>
}
export {
    StoneRockScissorContext,
    StoneRockScissorContextProvider
}