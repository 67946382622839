import React, { useContext, useState } from 'react';
import Card from './GameCard';
import StyledWrapper from '../styleguides/StyledGameCardsWrapper';
import Styled from 'styled-components';
import {StyledText} from '../styleguides/StyledText';
import {StyledActionButton} from '../styleguides/StyledActionButton';
import { UserContext } from '../context/UserContext';


const StyledInput = Styled.input`
border: none
padding: 0.1rem 1rem 
margin: 1rem 0rem
font: small-caption	
font-size: 1rem
width: calc(100% - 50px)
color: $dark-blue-light
outline: none
line-height: 1.5
border-radius: 15px` ;


export default function Login({history}) {
    const { user, login } = useContext(UserContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    if(user) {
        history.push('/trydicy')
        return null;
    }

    return(
<StyledWrapper>
<StyledText> Welcome to Dicy! You must login before using the app<span role="img" aria-label="key">🔑</span></StyledText>
    <Card>
        <StyledText>Username</StyledText>
        <StyledInput type='username' onChange={e => setUsername(e.target.value)}></StyledInput>
        <StyledText>Password</StyledText>
        <StyledInput type='password' onChange={e => setPassword(e.target.value)}></StyledInput>     
        <StyledActionButton onClick={()=> login({ username, password })}><StyledText>Login</StyledText></StyledActionButton>
        <StyledActionButton onClick={() => history.push("/register")}><StyledText>Register</StyledText></StyledActionButton>
    </Card>
    </StyledWrapper>
    )
}
