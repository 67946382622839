import React, {useContext} from 'react';
import styled from 'styled-components';
import { StyledButton } from '../styleguides/StyledButton';
import { withRouter } from 'react-router-dom';
import { BackgroundContext } from "../context/BackgroundContext";
import { UserContext } from '../context/UserContext';


const AppTitle = styled.h1`
font-family: 'Lobster', cursive;
font-size: ;
cursor: pointer;
color: #fff;
margin: 0;
`;

const StyledLink = styled.a`
text-decoration: none;
color: black
cursor: pointer;
`;


const StyledNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.7rem;`
 ;

const Actions = styled.div`
  display: flex;

  button {
    margin: 0 0.5rem;
  }
  `;

 function Navbar(props) {

  const { toggleNightDay, toogleButtonContent } = useContext(BackgroundContext);
  const { user } = useContext(UserContext);

    return(
        <StyledNavbar>
        <StyledLink href="/"><AppTitle>Dicy</AppTitle></StyledLink>
        <Actions>
        <StyledButton onClick={()=> toggleNightDay()}>{toogleButtonContent}</StyledButton>
        {
          user && (
            <StyledButton onClick={()=>props.history.push("/user")}>{user.username}</StyledButton>
          )
        }
        </Actions>
      </StyledNavbar>
    )
} export default withRouter(Navbar);