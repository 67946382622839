import React from 'react';
import styled from 'styled-components';
import Card from './GameCard';




const CardChangingaCard = styled.div`
  background-image: ${props => props.background}; 
`


export default function ColorChangingCard(props) {




    return (<CardChangingaCard><Card>{props.children}</Card></CardChangingaCard>)
}