import React, { useState, createContext } from 'react';
import defaultParticles from '../assets/defaultParticles';
import useLocalStorage from '../hooks/useLocalStorage';

const defaultBackground = "linear-gradient(to bottom,#000000cc 0%,#4c0000d1 100%)";
const dayBackground = "linear-gradient(to bottom,#6eb8ce 0%,#f19292 100%)";
const dayEmoji = "☀️";
const nightEmoji = "🌑";

const BackgroundContext = createContext({
    displayParticles: true,
    setDisplayParticles: x => { },
    background: defaultBackground,
    setBackground: x => { },
    particles: defaultParticles,
    setParticles: x => { },
    toogleButtonContent: dayEmoji,
    setToggleButtonContent: x => { },
});


function BackgroundContextProvider({ children }) {
    const [storedBackground, setStoredBackground, rD] = useLocalStorage("background", defaultBackground);
    const [storedButtonContent, setStoredButtonContent, rB] = useLocalStorage("button", dayEmoji);
    const [displayParticles, setDisplayParticles] = useState(true);
    const [background, setBackground] = useState(storedBackground);
    const [particles, setParticles] = useState(defaultParticles);
    const [toogleButtonContent, setToggleButtonContent] = useState(storedButtonContent);

    function toggleNightDay() {
        if (background === defaultBackground) {
            setBackground(dayBackground);
            setStoredBackground(dayBackground);
            setToggleButtonContent(nightEmoji);
            setStoredButtonContent(nightEmoji);
        } else {
            setBackground(defaultBackground);
            setStoredBackground(defaultBackground);
            setToggleButtonContent(dayEmoji);
            setStoredButtonContent(dayEmoji);
        }
    }


    return (
        <BackgroundContext.Provider value={{
            displayParticles,
            setDisplayParticles,
            background,
            setBackground,
            setParticles,
            particles,
            toggleNightDay,
            toogleButtonContent,
            defaultBackground,
            dayBackground
        }}>
            {children}
        </BackgroundContext.Provider>
    )

}


export {
    BackgroundContext,
    BackgroundContextProvider
}

