import React, { useContext, createContext, useState, useEffect } from "react";
import Styled from 'styled-components';
import {GameContext} from './GameContext';
import ColorChangingCard from "../components/ColorChangingCard";


const GetTwoSimularCardGameContext = createContext({
    choises: null,
    choiseCounter: 0,
}); 

const GetTwoSimularCardGameContextProvider = function ({children}) {
    const { players, setPlayers, playerTurn, setPlayerTurn, setResult, result} = useContext(GameContext);
    const [choises, setChoises] = useState(null);
    const [choiseCounter, setChoiseCounter] = useState(0);


    const getRandomNumber = () =>{
        const randomNumber = Math.floor(Math.random * 2);
        return(randomNumber);
    }

    const createRandomCard = () =>{
        const randomNubmer = getRandomNumber();
        if (randomNubmer === 1){
        return(<ColorChangingCard/>)
     } if (randomNubmer === 2){
         return (<ColorChangingCard style={Styled} />)
     }
    
    
    }


    return <GetTwoSimularCardGameContext.Provider
    value={{
        players,
         setPlayers, 
         playerTurn, 
         setPlayerTurn, 
         setResult, 
         result,
         createRandomCard,
         choises,
         setChoiseCounter
    }}>{children}</GetTwoSimularCardGameContext.Provider>
}

export {
GetTwoSimularCardGameContext,
GetTwoSimularCardGameContextProvider
}

   
