import React, { useContext } from 'react';
import Card from './GameCard';
import {StyledText} from '../styleguides/StyledText';
import {StyledActionButton} from '../styleguides/StyledActionButton';
import StyledWrapper from '../styleguides/StyledGameCardsWrapper';
import { UserContext } from '../context/UserContext';
import { StyledLink } from '../styleguides/StyledLink';



export default function User(props){
    const { user, logout } = useContext(UserContext);
    
    if(!user) {
        props.history.push('/');

    }
    
    return(
        <StyledWrapper>
        <Card>  
            <StyledText>
                Welcome to your page lord {user? user.username: null}! 
                 <StyledActionButton onClick={logout}> Log out</StyledActionButton>
                 <StyledActionButton onClick={() => alert('Find help within yourself to discover what you truly need...')}>help</StyledActionButton>
            </StyledText>
        </Card>
       
       <StyledLink to="/"> <Card>  
            <StyledText>
                Go back to dicy
            </StyledText>
        </Card>
        </StyledLink>
     </StyledWrapper>
     ) 
}