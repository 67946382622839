import React, { useContext} from 'react';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Applications from './components/Applications';
import { Switch, Route } from 'react-router-dom';
import Register from './components/Register';
import User from './components/User';
import ParticlesSimple from './components/ParticlesSimple';
import styled from 'styled-components';
import Dicy from './components/RockPaperScissor';
import { BackgroundContext } from './context/BackgroundContext';


//background-image: linear-gradient(to bottom,#ff9797f0 0%,#0072ff 50%,#4d037b 100%)
 
const ParticleWrapper = styled.div`
  position: fixed;
  z-index: -1
   width: 100vw;
  min-height: 100vh;
  background-image: ${props => props.background};    
`;

const App = props => {

const {displayParticles, background} = useContext(BackgroundContext);

  return (
    <div>
      
      <ParticleWrapper background={background}>
        {
          displayParticles && (
            <ParticlesSimple />    
          )
        }
      </ParticleWrapper>  
  <Navbar /> 
  <div>
    <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/trydicy" exact component={Applications} />
        <Route path="/trydicy/dicy" exact component={Dicy} />
        <Route path="/register" exact component={Register} />
        <Route path="/user" exact component={User} />
    </Switch>
    </div> 
    </div>
  );
}

export default App;
