import React, { useState, createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';


const UserContext = createContext({
    user: null,
    login: usr => {},
            logout: () => {},
            registerUser: usr => {}
})


function UserContextProvider({children}) {
    const [storedUser, setStoredUser, removeStoredUser] = useLocalStorage("user");
    const [storedUsers, setStoredUsers] = useLocalStorage("registeredUsers");
    const [user, setUser] = useState(()=>storedUser);

    function registerUser(newUser) {
        if(storedUsers && storedUsers.some(usr => usr.username === newUser.username)) {
            throw new Error("User is already registered!");
        } else {
            if(!storedUsers) {
                setStoredUsers([newUser]);
            } else {
                setStoredUsers([
                    newUser,
                    ...storedUsers
                ]);
            }
        }
    }


    function login(loginInfo) {
        const aUser = storedUsers
        .find(existingUser => existingUser.username === loginInfo.username && existingUser.password === loginInfo.password);
       
        if(aUser) {
            setUser(aUser);
            setStoredUser(aUser);
            return true;
        }
        return false;
    }

    function logout() {
        removeStoredUser();
        setUser(null);
    }

    return(
        <UserContext.Provider value={{
            user,
            login,
            logout,
            registerUser
            }}>
        {children}
        </UserContext.Provider>)   
}


export {
    UserContext,
    UserContextProvider
}