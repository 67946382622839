import React from 'react';
import styled from 'styled-components';


const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1.5rem;
`;


const CardBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 padding: 3.336912rem;
  // max padding =2.336912rem
  color: #ffffff;
  font-weight: bold;
  // background-image: linear-gradient(to top, #ffffffd6 0%,#c79b8300 100%);
  background: #ffffff15;
  border-radius: 25px;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
  border: 5px solid #ffffff41;
  transition: all 0.22678s ease-in-out;
  &: hover{
    background: #00000099;
  }
 
  

`;


export default function Card(props) {
 
    return(
       <CardWrapper>
         <CardBox>
          {props.children}
        </CardBox>
       </CardWrapper>
    )
}