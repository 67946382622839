export default {
    particles: {
      number: {
        value: 73,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: ["#FFFFFF"]
      },
      shape: {
        type: "circle",
        
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: {
          enable: true,
          speed: 0.1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 4,
        random: true
      },
      line_linked: {
        enable: true
      }
    },
    retina_detect: true
  };