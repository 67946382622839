import React, {useContext} from 'react';
import styled from 'styled-components';
import Card from './GameCard';
import { GameContext } from '../context/GameContext';
import { StyledLink } from '../styleguides/StyledLink';
import { UserContext } from '../context/UserContext';
import ColorChangingCard from './ColorChangingCard';


/*
      <StyledLink href="/chalengerGame" cursor="pointer"><Card>ENJOY</Card></StyledLink>
      <StyledLink href="/ChalengerLife" cursor="pointer" ><Card>BELDR </Card></StyledLink>
      <StyledLink href="/DicyWorld" cursor="pointer" ><Card>DWMAP</Card></StyledLink> */


const CardWrapper = styled.div`
width: 100%;
height: 100vh;
margin: 1rem;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
color: #fff;
@media (min-width: 940px){
    flex-direction: row;
    flex-wrap: null;
}
`;


    
/* kan bruke props og onclick i Card*/

export default function Apps(props){
    const { user } = useContext(UserContext);

    if(!user) {
        props.history.push("/");
    }

return(
<div>
    <CardWrapper>
      <StyledLink to="/trydicy/dicy" cursor="pointer" ><Card>StoneRockScissor</Card></StyledLink>
      <StyledLink to="/" cursor="pointer" ><Card>Coming soon</Card></StyledLink>
      <StyledLink to="/" cursor="pointer" ><Card>Coming soon</Card></StyledLink>
      <StyledLink to="/" cursor="pointer" ><Card>Coming soon</Card></StyledLink>
   </CardWrapper>
      </div>
)
}


