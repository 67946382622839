import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { StoneRockScissorContext } from "../context/StoneRockScisssorContext";
import { BackgroundContext } from "../context/BackgroundContext";
import deerParticles from "../assets/deerParticles";
import defaultParticles from "../assets/defaultParticles";
import Card from "./SRSCard";
import { StyledText } from "../styleguides/StyledText";
import { StyledButton } from "../styleguides/StyledButton";
import StyledGameCardWrapper from "../styleguides/StyledGameCardsWrapper";


const GamePlatform = styled.div`
  color: #fff;
`;

const StyledEmojiText = styled.p`
margin: 0rem 1rem;
font-size: 2rem;
font-weight: bold;
cursor: pointer;
`;





export default function RockPaperScissor() { 
    const {
    firstPlayerWinResultCounter,
    secondPlayerWinResultCounter,
    selectType,
    resetGame,
    players,
    playerTurn,
    isGameOver,
    result,
    firstPlayerSelection,
    secondPlayerSelection,
    choices,
    validateResults,
    selectRandomChoice} = useContext(StoneRockScissorContext);
  
    const {setParticles} = useContext(BackgroundContext);

  
  useEffect(() => {
    setParticles(deerParticles);
    return () => { setParticles(defaultParticles); }
  }, [setParticles]);

  useEffect(() => {
    if(playerTurn > 0) {setTimeout(() => selectRandomChoice(), 500);}

  }, [playerTurn]);

  return (
    <GamePlatform>
        <StyledGameCardWrapper>
      {!isGameOver && choices.map(choice => (

    <StyledEmojiText onClick={() => {selectType(choice)}}>
        <Card 
              key={choice.type}
            >{choice.type}</Card></StyledEmojiText>
      ))}
      
       {
            firstPlayerSelection && (
                <Card>  <StyledText>{`${players[0].username} chose ${firstPlayerSelection.type}!`}</StyledText> </Card>
            )
           
        }

        {
            secondPlayerSelection && (
               <Card><StyledText>{`${players[1].username} chose ${secondPlayerSelection.type}!`}</StyledText></Card> 
            )
        }     
        {
            result && (
               <Card> 
                   <StyledText>{result}</StyledText>
                   <StyledText>{players[0].username} {firstPlayerWinResultCounter}</StyledText>
                   <StyledText>{players[1].username} {secondPlayerWinResultCounter}</StyledText>              
               </Card>
            )
        }
        { isGameOver && (
            <Card><StyledButton onClick={() => resetGame()}>Play again?</StyledButton></Card>
        )}
   
</StyledGameCardWrapper>
    </GamePlatform>
  );
}
