import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-evenly;
color: #fff;
`;

export default function StyledGameCardWrapper (props){
    return(
      
    <Wrapper>{props.children}</Wrapper>
    )}